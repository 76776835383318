<template>
	<div>
		<FullPageLoadingIndicator />
		<styled-modal
			:start-open="showQuizModal"
			ref="modal"
			@on-close="onQuizModalClose">
			<template v-slot:panel="slotProps">
				<div class="flex flex-col gap-2 p-4">
					<div class="flex gap-2 justify-center">
						<ExclamationCircleIcon
							class="h-8 w-8 text-yellow-500" />
						<h2 class="m-0 mb-4">Special Note</h2>
					</div>
					<p class="m-0">
						The conversions and equivalents tested in this quiz are
						based on the Apothecaries’ System covered in the video
						lesson, NOT modern, American standard measures.
						Therefore, you will need to refer to the equivalents
						provided in the lesson to successfully complete this
						quiz.
					</p>
					<div class="flex mt-8 gap-2 items-center justify-center">
						<button
							@click="slotProps.events?.closeModal()"
							class="button main rounded-md bg-gray-100 shadow-none border-none hover:bg-gray-200">
							Ok, got it!
						</button>
					</div>
				</div>
			</template>
		</styled-modal>
		<PrepAnnouncementModal v-if="isManager"></PrepAnnouncementModal>
		<Modal />
		<DeprecatedCourseModal
			deprecatedCourseSlug="fundamentals-of-fluoroscopic-imaging" />
		<Toaster />
		<SiteRibbon />
		<SidebarUser
			v-if="!admin"
			:theme="theme"
			:impersonating="impersonating" />
		<SidebarAdmin v-if="admin" />
		<BladeContent :show-if-blade="true">
			<TitleBar
				:action="action"
				:actionPath="actionPath"
				:app="app"
				:icon="icon"
				:moduleTitle="moduleTitle"
				:resourceTitle="resourceTitle"
				:searchable="searchable"
				:searchAddition="searchAddition"
				:showGlobalSearch="showGlobalSearch"
				:searchClear="searchClear"
				:section="section"
				:sectionPath="sectionPath"
				:showBilling="showBilling"
				:impersonating="impersonating" />
		</BladeContent>
		<BladeContent :show-if-blade="false">
			<router-view name="titleBar"></router-view>
		</BladeContent>

		<section class="flex flex-col content pt-17 sm:pt-20 sm:pl-24 h-screen">
			<slot name="banner" />
			<AlertBanner
				v-if="isManager"
				type="info"
				dismissable
				alertName="cognitive-load-webinar-info">
				<strong
					>Want to maximize the impact of your Clover Learning
					curriculum? </strong
				><br />
				Join our upcoming webinar to learn about Cognitive Load Theory,
				curriculum mapping, ASRT® requirements, and more!
				<a
					href="https://events.zoom.us/ev/AnsC5zCQMMUYXoQeb5vnOhKT47s6umq_gp7LSEfZ9daH-t3gLkyE~AkbCukAW91i5OhwH_36G8Ovf-s50PHrTgNcGiiSFzoFu71DH0CXF1ZrzEw"
					target="_blank"
					class="text-blue-700 underline hover:no-underline"
					>Register Now</a
				>
			</AlertBanner>
			<AlertBanner
				v-if="showMaintenanceBanner"
				type="warning"
				dismissable
				alertName="expiry-alert-warning">
				Scheduled Maintenance: Our platform will undergo a brief 1-hour
				update on <b>February 13</b> at <b>10 a.m. EST</b>. During this
				time, minor service disruptions may occur. Thank you for
				understanding.
			</AlertBanner>
			<AlertBanner
				v-if="
					user.type === 'academic' &&
					user.access_days_remaining <= 7 &&
					user.access_days_remaining > 0
				"
				type="warning"
				alertName="expiry-alert-warning">
				You have
				<strong
					>{{ user.access_days_remaining }} days of access remaining.
				</strong>
				<a href="/redeem" class="text-blue-600"
					>Manage your access codes</a
				>
				to continue using the platform.
			</AlertBanner>
			<AlertBanner
				v-if="
					user.type === 'academic' && user.access_days_remaining <= 0
				"
				type="danger"
				alert-name="expiry-alert-expired">
				<strong>Your access code has expired! </strong>
				<a href="/redeem" class="text-blue-600"
					>Redeem a new access code</a
				>
				or
				<a href="/account/checkout/select-plan" class="text-blue-600"
					>purchase a subscription</a
				>
				to continue using the platform.
			</AlertBanner>
			<div
				class="flex-grow py-4 sm:py-8"
				:class="{
					'px-8': wide,
					container: !wide,
				}">
				<PageSearch
					v-if="searchable?.length && pageSearch"
					class="sm:hidden pt-2 border-b border-gray-200 mb-6 pb-6"
					v-bind="{
						app,
					}" />
				<PageTitle
					v-if="app !== 'Home'"
					class="flex justify-center sm:hidden border-b border-gray-200 mb-6 pb-6"
					v-bind="{
						action,
						actionPath,
						app,
						icon,
						section,
						sectionPath,
					}" />
				<main id="main">
					<slot />
				</main>
			</div>
			<slot name="footer" />
		</section>
	</div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BladeContent from "../BladeContent.vue";
import { mapState } from "vuex";
import SkipToContent from "../_elements/SkipToContent.vue";
import Modal from "../_elements/modals/Modal.vue";
import Toaster from "../_elements/Toaster.vue";
import SiteRibbon from "../_elements/SiteRibbon.vue";
import SidebarUser from "./SidebarUser.vue";
import SidebarAdmin from "./SidebarAdmin.vue";
import TitleBar from "./TitleBar.vue";
import PageSearch from "../_elements/PageSearch.vue";
import PageTitle from "../_elements/PageTitle.vue";
import FullPageLoadingIndicator from "@/components/ui/loaders/FullPageLoadingIndicator.vue";
import WelcomeModal from "../Dashboard/WelcomeModal.vue";
import StyledModal from "../_elements/modals/StyledModal.vue";
import SuccessModal from "../Dashboard/SuccessModal.vue";
import FloodgateModal from "../Dashboard/FloodgateModal.vue";
import DeprecatedCourseModal from "../Dashboard/DeprecatedCourseModal.vue";
import { analytics } from "@/services/segment.ts";
import AlertBanner from "@/components/_elements/alerts/AlertBanner.vue";
import {
	ExclamationCircleIcon,
	ExclamationTriangleIcon,
} from "@heroicons/vue/20/solid";
import AnnouncementModal from "../Dashboard/AnnouncementModal.vue";

export default defineComponent({
	components: {
		AlertBanner,
		FullPageLoadingIndicator,
		PageTitle,
		PageSearch,
		TitleBar,
		SidebarAdmin,
		SidebarUser,
		SiteRibbon,
		Toaster,
		BladeContent,
		SkipToContent,
		Modal,
		WelcomeModal,
		FloodgateModal,
		StyledModal,
		SuccessModal,
		DeprecatedCourseModal,
		ExclamationTriangleIcon,
		ExclamationCircleIcon,
		AnnouncementModal,
	},
	props: {
		action: String,
		actionPath: String,
		active: String,
		admin: Boolean,
		app: String,
		icon: String,
		learnerMode: Boolean,
		moduleTitle: String,
		resourceTitle: String,
		searchable: Array,
		searchAddition: String,
		searchClear: Boolean,
		section: String,
		sectionPath: String,
		showGlobalSearch: {
			type: Boolean,
			default: false,
		},
		showBilling: Boolean,
		theme: String,
		user: Object as PropType<any>,
		permissions: Object,
		settings: Object,
		websitePath: String,
		wide: Boolean,
		impersonating: Boolean,
		features: Object,
	},
	computed: {
		...mapState(["pageSearch"]),
		showQuizModal() {
			return (
				window.location.href.includes("apothecaries-system-quiz") &&
				sessionStorage.getItem("apothecaries-quiz-modal") !== "false"
			);
		},
		isManager() {
			const roles = [
				"admin",
				"admin-view-client-reports",
				"panorama-admin",
				"partner-owner",
				"partner-admin",
				"group-owner",
				"group-admin",
			];
			// if user.role is in roles
			return roles.includes(this.user.role);
		},
		showMaintenanceBanner() {
			return import.meta.env.VITE_SHOW_MAINTENANCE_BANNER === "true";
		},
	},
	methods: {
		onQuizModalClose: function () {
			sessionStorage.setItem("apothecaries-quiz-modal", "false");
		},
	},
	created() {
		analytics.page();
		this.user.permissions = this.permissions;
		this.user.settings = this.settings;
		this.user.learnerMode = this.learnerMode;

		// @ts-ignore
		this.$store.commit("registerState", {
			activePath: this.active,
			token: (document.querySelector('meta[name="token"]') as any)
				.content,
			user: this.user,
			websitePath: this.websitePath,
		});
	},
});
</script>
